import React, { Component } from "react";
import { Layout } from "antd";

import Sidebar from "../Sidebar/index";
import axios from "axios";
import Topbar from "../Topbar/index";
import { footerText } from "util/config";
import App from "routes/index";
import config from "../../../config";

const { Content, Footer } = Layout;

export class MainApp extends Component {
  state = {
    admin: "",
  };

  getAdmin = () => {
    axios.get(`${config.API_URL}/admin/get/me`, {}).then((response) => {
      console.log(response);
      this.setState({
        admin: response.data.data,
      });
    });
  };

  componentDidMount() {
    this.getAdmin();
  }

  render() {
    const { match } = this.props;
    return (
      <Layout className="gx-app-layout">
        <Sidebar />
        <Layout>
          <Topbar />
          <Content className={"gx-layout-content "}>
            <App match={match} role="admin" data={this.state.admin} />
            <Footer>
              <div className="gx-layout-footer-content">{footerText}</div>
            </Footer>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default MainApp;
