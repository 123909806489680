import React, { Component } from "react";
import { Layout } from "antd/lib/index";
import { Link } from "react-router-dom";
import axios from "axios";
import config from "../../../config";
import Auxiliary from "util/Auxiliary";
import { connect } from "react-redux";
import { toggleCollapsedSideNav } from "../../../appRedux/actions";
import { TAB_SIZE } from "../../../constants/ThemeSetting";
const { Header } = Layout;

class Topbar extends Component {
  state = {
    doc: "",
    hold: false,
    collection: false,
  };

  getAdmin = () => {
    const token = localStorage.getItem("token");
    var endpoint =
      this.props.auth.user.role == "doctorNurse"
        ? "get/current/nurse"
        : "get/current/doctor";
    axios.get(`${config.API_URL}/doctor/${endpoint}`, {}).then((response) => {
      var hold =
        response.data.data.accountOnHold ||
        response.data.data.practice?.accountOnHold;
      this.setState({ hold });
      console.log("hold", hold);
      var collection =
        response.data.data.accountInCollection ||
        response.data.data.practice?.accountInCollection;
      this.setState({ collection });
      console.log("collection", collection);

      localStorage.setItem("accountOnHold", hold);
      localStorage.setItem("accountInCollection", collection);
      localStorage.setItem(
        "ptan",
        response.data.data.ptan ? response.data.data.ptan : false
      );
      this.setState({
        doc: response.data.data,
      });
    });
  };

  componentDidMount() {
    this.getAdmin();
  }

  render() {
    // Conditionally set the background color based on accountInCollection or accountInHold
    let headerStyle = {};
    if (this.state.doc && this.state.collection) {
      headerStyle.backgroundColor = "red";
      headerStyle.color = "white";
    } else if (this.state.doc && this.state.hold) {
      headerStyle.backgroundColor = "red";
      headerStyle.color = "white";
    }

    return (
      <Auxiliary>
        <Header style={headerStyle}>
          {/* {this.state.doc.accountInCollection && (<span>Advances Diagnostic IMaGING - Your Account Is Curently on HOld</spna>)} */}
          <span>
            {this.props.auth &&
              this.props.auth.isAuthenticated &&
              this.state.doc?.name}
            /
            {this.state.doc &&
              this.state.doc.practice &&
              this.state.doc.practice.name}
            {(this.state.hold || this.state.collection) && (
              <span> - Your Account Is Currently On Hold</span>
            )}
          </span>

          {this.props.setting.width < TAB_SIZE ? (
            <div className="gx-linebar gx-mr-3">
              <i
                className="gx-icon-btn icon icon-menu"
                onClick={() => {
                  this.props.dispatch(
                    toggleCollapsedSideNav(!this.props.setting.navCollapsed)
                  );
                }}
              />
            </div>
          ) : null}
          <Link to="/login" className="gx-d-block gx-d-lg-none gx-pointer">
            MEDSHIP
          </Link>
        </Header>
      </Auxiliary>
    );
  }
}

const mapStateToProps = (state) => ({
  setting: state.setting,
  auth: state.authR,
});

export default connect(mapStateToProps)(Topbar);
